/*
 * @Author: your name
 * @Date: 2020-07-03 17:33:49
 * @LastEditTime: 2020-07-17 10:56:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\views\companyIntroduction\config\config.js
 */ 
const listMap = [
  {
    image: require("../../../assets/images/companyIntroductionImage/history.png"),
    title: "企业背景",
    info:"作为一家实验室培育钻石珠宝企业，我们在国内较早突破了生物钻石培育技术，创造了“以新生儿纪念钻石存储生命最初印记”的产品理念，整合了钻石培育、切割、镶嵌、设计等全产业链资源，满足了用户从钻石原石到钻石饰品的个性化和多样化定制需求。",
    title1: "",
    info1:
      ''
  },
  {
    image: require("../../../assets/images/companyIntroductionImage/patent.png"),
    title: "技术保障",
    info:
      "我们在实验室培育钻石领域已深耕十五年，拥有世界先进的宝石级培育钻石技术和生物组织碳化技术，为碳源萃取及高品级培育钻石制备提供了技术保障。",
    title1: "",
    info1:
      ""
  },
  {
    image: require("../../../assets/images/companyIntroductionImage/factory.png"),
    title: "国际标准",
    info:
      "我们的钻石不但拥有更高品级，而且采用国际顶级切割工艺标准。我们与比利时安特卫普国际钻石交易中心、以色列特拉维夫钻石交易中心以及美国纽约钻石交易中心等国际顶级钻石切割打磨机构均有密切合作，我们的产品在GIA、IGI、HRD Antwerp、CTI等国际权威机构均可进行鉴定和分级认证。",
    title1: "",
    info1:
      ""
  },
  {
    image: require("../../../assets/images/companyIntroductionImage/diamond.png"),
    title: "绿色环保",
    info:
      "我们的钻石制备流程满足环保要求，符合《金伯利进程》和联合国《Kyoto Protocol》决议，从根源上避免了自然矿石资源开采及非法走私贸易冲突，为建立人类和平社会，构造可持续发展的生态环境做出了贡献。",
    title1: "",
    info1:
      ""
  }
];

export { listMap };
