<template>
  <div class="content-container">
    <div class="info">
      <p class="info-title">公司简介</p>
      <p class="info-text" style="text-align:justify">
      塞维利亚（北京）文化科技有限公司是业内领先的经营实验室培育钻石业务的珠宝企业。
    我们在实验室培育钻石领域已深耕十五年，拥有世界先进的宝石级培育钻石技术和生物组织碳化技术。近年来，随着公司的快速发展，我们在中国及欧洲国家建立了多个顶级研发中心和生产基地。
      </p>
      <p class="info-text" style="text-align:justify">
      公司旗下品牌<span class="irisgem">伊莉丝®</span>，是国内首个初生纪念钻石品牌，为顾客提供新生儿纪念钻石高端定制服务。
      塞维利亚公司致力于以独特的产品和设计理念，打造中国最具影响力的培育钻石珠宝品牌。
      </p>
      
    <p class="info-text" style="text-align:justify">
    </p>
       <p class="info-text" style="text-align:justify">
      </p>



    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.irisgem{
  font-weight: 600
}
.content-container {
  width: 100%;
  height: 1400px; /* no */
  background-image: url("../../../assets/images/companyIntroductionImage/introduction.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; 
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px; /* no */
}

.info {
  padding: 90px 80px 116px 80px; /* no */
  background: #ffffff;
  width: 766px; /* no */
  height: 760px;/* no */
  display: block;
  .info-title {
    font-size: 50px; /* no */
    color: #921d22;
    position: relative;
    text-align: center;
    padding-bottom: 38px; /* no */
    font-family: PingFangSC-Medium, PingFang SC;
    &::after {
      content: " ";
      width: 50px; /* no */
      height: 2px; /* no */
      background: #666666;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    }
  }
  .info-text:nth-child(1){
    margin-top: 36px/* no */
  }
  .info-text {
   
    font-size: 20px; /* no */
    color: #000000;
    font-family: PingFangSC-Medium, PingFang SC;
    line-height: 50px;/* no */
    text-indent: 45px;/* no */
  }
}
@media only screen and (max-width: 640px) {
  .content-container {
    padding: 40px 40px;
    height: 900px; /* no */
    background-size: fill;
    background-attachment: inherit;
  }
  .info {
    padding: 30px; /* no */
    .info-title {
      font-size: 46px;
    }
    .info-text {
      font-size: 20px; /* no */
    }
  }
}
@media only screen and (min-width: 640px) and (max-width: 960px) {
  .content-container {
    padding: 40px 40px;
    height: 900px; /* no */
    background-size: fill;
    background-attachment: inherit;
  }
  .info {
    width: 566px; /* no */
    padding: 50px; /* no */
    .info-text {
      font-size: 14px; /* no */
    }
  }
}
@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .info {
    padding: 50px; /* no */
    .info-text {
      font-size: 16px; /* no */
    }
  }
}
@media only screen and (min-width: 1200px) {
  .content-container {
    width: 1600px;
    margin: 80px auto; /* no */
  }
}
</style>
