<template>
  <div class="content-container">
    <div class="info">
      <p class="info-title">
        Are you interested in Memory Diamond
      </p>
      <p class="info-text">
        如果您对伊莉丝钻石感兴趣,欢迎致电洽谈合作!
      </p>
      <div class="info-btn">
        JOIN US
        <div class="light">
          <div class="btn-light_effects"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content-container {
  width: 100%;
  background-image: url("../../../assets/images/companyIntroductionImage/banner.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  margin-top: 120px; /* no */
}

.info {
  margin: 0;
  padding: 130px 80px 130px 213px; /* no */
  display: block;
  height: 600px;
  .info-title {
    width: 482px; /* no */
    font-size: 48px; /* no */
    color: #ffffff;
    text-shadow: 5px 5px 5px #000000; /* no */
    font-family: PingFangSC-Medium, PingFang SC;
    margin-bottom: 30px; /* no */
  }
  .info-text {
    width: 388px; /* no */
    font-size: 24px; /* no */
    color: #ffffff;
    text-shadow: 5px 5px 5px #000000; /* no */
    font-family: PingFangSC-Medium, PingFang SC;
    margin-bottom: 60px; /* no */
  }
  .info-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 255px; /* no */
    height: 60px; /* no */
    border-radius: 5px; /* no */
    background-color: #921d22;
    font-size: 16px; /* no */
    color: #ffffff;
    font-family: PingFangSC-Medium, PingFang SC;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    transition: blue 0.2s ease-in-out, blue 0.2s ease-in-out,
      blue 0.2s ease-in-out, blue 0.2s ease-in-out;
    .light {
      animation: light 4s infinite;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .btn-light_effects {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0)
        );
      }
    }
    @keyframes light {
      20% {
        transform: translateX(100%);
      }

      100% {
        transform: translateX(100%);
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .content-container {
    padding: 40px 40px;
    background-size: fill;
    background-attachment: inherit;
  }
  .info {
    padding: 0; /* no */
    .info-title {
      font-size: 26px; /* no */
      width: 100%;
      text-align: center;
    }
    .info-text {
      width: 100%;
      font-size: 16px; /* no */
      text-align: center;
    }
    .info-btn {
      margin: 0 auto;
    }
  }
}

@media only screen and (min-width: 640px) and (max-width: 960px) {
  .content-container {
    padding: 40px 40px;
    background-size: fill;
    background-attachment: inherit;
  }
  .info {
    padding: 0; /* no */
    .info-text {
      font-size: 14px; /* no */
    }
  }
}

@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .info {
    padding: 50px; /* no */
    .info-text {
      font-size: 16px; /* no */
    }
  }
}

@media only screen and (min-width: 1200px) {
  .content-container {
    width: 1600px;
    margin: 80px auto; /* no */
  }
}
</style>
