<template>
  <div>
    <header-container />
    <content-container />
    <card-container />
    <bottom-container />
  </div>
</template>

<script>
import HeaderContainer from "./components/Header";
import ContentContainer from "./components/Content";
import CardContainer from "./components/Card";
import BottomContainer from "./components/Bottom";
export default {
  name: "CompanyIntroduction",

  components: {
    HeaderContainer,
    ContentContainer,
    CardContainer,
    BottomContainer
  }
};
</script>

<style lang="less" scoped></style>
