<template>
  <div class="card-container">
    <div class="container-title">We are experts in creating diamonds</div>
    <div class="card-list">
      <div v-for="(item, index) in list" :key="index" class="card">
        <div
          class="card-img"
          :style="{ backgroundImage: `url(${item.image})` }"
        ></div>
        <div class="card-text">
          <p class="title">{{ item.title }}</p>
          <p class="info">{{ item.info }}</p>
          <p class="title title1">{{ item.title1 }}</p>
          <p class="info info1">{{ item.info1 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listMap } from "../config/config";
export default {
  data() {
    return {
      list: listMap
    };
  }
};
</script>

<style lang="less" scoped>
.card-container {
  margin-top: 100px; /* no */
  padding: 0 20px;
}
.container-title {
  font-size: 42px; /* no */
  font-weight: 500;
  color: #000000;
  font-family: PingFangSC-Medium, PingFang SC;
  text-align: center;
  margin-bottom: 94px; /* no */
}

.card {
  display: flex;
  justify-content: center;
  margin-bottom: 129px; /* no */
  &:nth-child(even) {
    flex-direction: row-reverse;
    .card-text {
      margin-right: 120px; /* no */
    }
  }
  &:nth-child(odd) {
    .card-text {
      margin-left: 120px; /* no */
    }
  }
}
.card-img {
  width: 560px; /* no */
  height: 420px; /* no */
  background-size: cover;
  background-position: center;
  box-shadow: 2px 2px 20px #000000; /* no */
}
.card-text {
  width: 490px; /* no */
  .title {
    font-size: 30px; /* no */
    font-weight: 400;
    color: #921d22;
    font-family: PingFangSC-Medium, PingFang SC;
    margin-bottom: 30px; /* no */
  }
  .info {
    font-size: 20px; /* no */
    font-weight: 200;
    color: #000;
    font-family: PingFangSC-Medium, PingFang SC;
    margin-bottom: 30px; /* no */
    line-height: 26px; /* no */
    display: block;
    height:auto;
  }
  .title1 {
    color: #333333;
    font-size: 24px; /* no */
  }
  .info1 {
    margin-bottom: 0px; /* no */
  }
}
@media only screen and (max-width: 640px) {
  .card {
    flex-flow: column;
    margin-bottom: 80px; /* no */
    &:nth-child(even) {
      flex-direction: column;
      .card-text {
        margin-right: 0; /* no */
      }
    }
    &:nth-child(odd) {
      .card-text {
        margin-left: 0; /* no */
      }
    }
    .card-text {
      margin-top: 30px; /* no */
      width: 100%;
    }
  }
  .card-img {
    width: 100%; /* no */
    height: auto; /* no */
    background-repeat: no-repeat;
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
}

@media only screen and (min-width: 640px) and (max-width: 960px) {
  .card {
    flex-flow: column;
    &:nth-child(even) {
      flex-direction: column;
      .card-text {
        margin-right: 0; /* no */
      }
    }
    &:nth-child(odd) {
      .card-text {
        margin-left: 0; /* no */
      }
    }
    .card-text {
      margin-top: 30px; /* no */
    }
  }
  .card-img {
    width: 520px; /* no */
    height: 380px; /* no */
    background-size: cover;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .card-img {
    width: 520px; /* no */
    height: 380px; /* no */
    background-size: cover;
  }
}
@media only screen and (min-width: 1200px) {
}
</style>
