<template>
  <div class="header-container">
    <div class="image-box">
      <img
        src="@/assets/images/companyIntroductionImage/headerBaby.png"
        alt=""
      />
    </div>
    <div class="text-box">
      <p class="t1">企业使命</p>
      <p class="t2">用美丽、恒久的钻石传递每一个人的幸福</p>
      <p class="t1">企业愿景</p>
      <p class="t2">让每一个追求幸福的人都佩戴上我们的钻石</p>
      <p class="t1">企业价值观</p>
      <p class="t2">诚信、品质、热爱、责任</p>
      <!-- <p class="t3">
      以一种非凡的方式来诠释陪伴与关爱；
      </p>
      <p class="t4">
        为每一个宝宝送上最独特的新生礼物；
      </p>
      <p class="t4">
        给每一位妈妈带来最愉悦的心灵感受；
      </p>
      <p class="t4">
        为每一个家庭打造最深刻的生命印记。
      </p> -->
      <!-- <p class="s1">核心价值</p>
      <p class="t2">CORE VALUE</p>
       <p class="t3">
      诚信、品质、热爱、责任
      </p> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.header-container {
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.4s;
}

.text-box {
  margin-top: 75px; /* no */
  p {
    font-family: PingFangSC-Medium, PingFang SC;
  }
  .t1 {
    font-size: 28px; /* no */
    margin-bottom: 20px; /* no */
    color: #921d22;

  }
  .t2 {
    font-size: 20px; /* no */
    margin-bottom: 63px; /* no */
  
  }
  .t3 {
    font-size: 20px; /* no */
    margin-bottom: 16px; /* no */
  }
  .t4 {
    font-size: 20px; /* no */
    margin-bottom: 20px;/* no */
  }
}
 .s1 {
    font-size: 34px; /* no */
    margin-bottom: 11px; /* no */
    margin-top: 38px; /* no */
  }


.image-box {
  img {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .header-container {
    flex-flow: column;
  }

  .image-box {
    width: 100%; /* no */
  }

  .text-box {
    margin-top: 30px;
    margin-left: 0px; /* no */
    text-align: center;
  }
}

@media only screen and (min-width: 640px) and (max-width: 960px) {
  .header-container {
    flex-flow: column;
  }

  .image-box {
    width: 521px; /* no */
    img{
      margin-left: 184px;/* no */
    }
  }

  .text-box {
    margin-top: 30px;
    margin-left: 0px; /* no */
    text-align: center;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .image-box {
    width: 421px; /* no */
  }

  .text-box {
    margin-left: 160px; /* no */
  }
}

@media only screen and (min-width: 1200px) {
  .image-box {
    width: 621px; /* no */
  }

  .text-box {
    margin-left: 160px; /* no */
  }
}
</style>
